<script setup lang="ts">
defineProps<{
  label?: string
  options: readonly { value: string; text: string }[]
}>()
const model = defineModel<string>()
</script>
<template>
  <label class="flex h-min flex-col gap-1 rounded-lg bg-white">
    <select
      v-model="model"
      class="border-inset h-min w-full cursor-pointer items-center gap-2 rounded-lg border-1 border-dashed border-slate-500/50 bg-slate-50/10 px-3 py-1.5 pr-9 pl-3 text-xs font-medium text-slate-800 outline-hidden transition-all hover:border-slate-500/50 focus:border-dashed focus:border-violet-300 focus:ring-0 focus:outline-hidden"
    >
      <option
        v-for="option in options"
        :key="option.value"
        :value="option.value"
        :selected="option.value == model"
      >
        {{ option.text }}
      </option>
    </select>
  </label>
</template>
